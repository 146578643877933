import React from "react"
export default function LegalImpressum() {
  return (
    <div
      className="p-8 sm:p-16 flex flex-col space-y-8"
      css={{
        minHeight: "calc(100vh - 5rem)",
      }}
    >
      <div className="flex flex-col">
        <div className="font-bold">brantpoint GmbH</div>
        <div>Schackstraße 2</div>
        <div>80539 München</div>
      </div>
      <div className="flex flex-col">
        <div className="flex space-x-4">
          <span>Tel.:</span>
          <span>+49 89 38172 0</span>
        </div>
        <div className="flex space-x-4">
          <span>E-Mail:</span>
          <span>info@brantpoint.io</span>
        </div>
        <div className="flex space-x-4">
          <span>Internet:</span>
          <span>www.brantpoint.io</span>
        </div>
      </div>
      <div className="space-y-8">
        <div className="flex flex-col sm:flex-row sm:space-x-4">
          <span className="font-bold">Umsatzsteueridentifikationsnummer:</span>
          <span>DE334377694</span>
        </div>
        <div className="flex flex-col sm:flex-row sm:space-x-4">
          <span className="font-bold">Handelsregister:</span>
          <span>HRB 259307 des Handelsregisters beim Amtsgericht München</span>
        </div>
      </div>

      <div className="flex flex-col">
        <span className="font-bold">Geschäftsführer:</span>
        <span>Stefan Groß</span>
      </div>
    </div>
  )
}
