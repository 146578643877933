import React from "react"
export default function LegalDatenschutz() {
  return (
    <div
      className="p-8 sm:p-16 flex flex-col"
      css={{
        minHeight: "calc(100vh - 5rem)",
      }}
    >
      <Heading num={1} text="Allgemeine Hinweise und Pflichtinformationen" />
      <div className="space-y-4">
        <SubHeading text="Datenschutz" />
        <Paragraph text="Wir behandeln personenbezogene Daten, die wir im Rahmen dieser Website erheben, entsprechend der gesetzlichen Datenschutzvorschriften sowie dieser Datenschutzerklärung. Für die Datenübertragung im Rahmen dieser Website wird aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher Inhalte, wie zum Beispiel Anfragen, die Sie an uns als Seitenbetreiber senden, eine SSL-bzw. TLS-Verschlüsselung verwendet." />
        <SubHeading text="Hinweis zur verantwortlichen Stelle" />
        <Paragraph text="Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website ist:" />
        <div className="flex flex-col">
          <div className="font-bold">brantpoint GmbH</div>
          <div>Schackstraße 2</div>
          <div>80539 München</div>
        </div>
        <div>
          <div className="flex space-x-4">
            <span>Tel.:</span>
            <span>+49 89 38172 0</span>
          </div>
          <div className="flex space-x-4">
            <span>E-Mail:</span>
            <span>info@brantpoint.io</span>
          </div>
          <div className="flex space-x-4">
            <span>Internet:</span>
            <span>www.brantpoint.io</span>
          </div>
        </div>
        <SubHeading text="Beschwerderecht bei der zuständigen Aufsichtsbehörde" />
        <Paragraph text="Im Falle datenschutzrechtlicher Verstöße steht Ihnen als betroffene Person ein Beschwerderecht bei einer Aufsichtsbehörde zu. Eine Liste der Datenschutzbeauftragten der Bundesländer in Deutschland sowie deren Kontaktdaten können folgendem Link entnommen werden:" />
        <a
          className="text-blue-600"
          href="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html"
        >
          https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html
        </a>
        {/* <SubHeading text="Recht auf Datenübertragbarkeit" />
        <Paragraph text="Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung oder in Erfüllung eines Vertrags automatisiert verarbeiten, an sich oder an einen Dritten in einem gängigen, maschinenlesbaren Format aushändigen zu lassen. Sofern Sie die direkte Übertragung der Daten an einen anderen Verantwortlichen verlangen, erfolgt dies nur, soweit es technisch machbar ist." /> */}
        <SubHeading text="Recht auf Auskunft, Einschränkung der Verarbeitung, Löschung" />
        <Paragraph text="Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit das Recht auf unentgeltliche Auskunft über Ihre gespeicherten personenbezogenen Daten, deren Herkunft und Empfänger und den Zweck der Datenverarbeitung und ggf. ein Recht auf Berichtigung, Einschränkung der Verarbeitung oder Löschung dieser Daten. Hierzu sowie zu weiteren Fragen zum Thema personenbezogene Daten können Sie sich jederzeit unter den Kontaktdaten der verantwortlichen Stelle an uns wenden." />
        <SubHeading text="Auftragsverarbeiter" />
        <Paragraph text="Die im Rahmen dieser Website erhobenen personenbezogenen Daten werden von unserem Server Hoster (Auftragsverarbeiter) in unserem Auftrag verarbeitet. Soweit die erhobenen Daten daneben anderen Empfängern zur Verfügung gestellt werden, ist dies unten bei den einzelnen Datenerhebungen bzw. Datenübermittlungen vermerkt." />
      </div>

      <Heading
        num={2}
        text="Datenerhebung mit Ausnahme von Analyse-Tools, Soziale Medien und Plugins"
      />
      <div className="space-y-4">
        <SubHeading text="Server-Log-Dateien" />
        <Paragraph text="Wenn Sie diese Seite aufrufen, erhebt und speichert unser Provider automatisch Informationen in sogenannten Server-Log-Dateien, die Ihr Browser automatisch an uns übermittelt. Dies sind:" />
        <Listing
          elements={[
            "Browsertyp und Browserversion",
            "Verwendetes Betriebssystem",
            "Referrer URL",
            "Hostname des zugreifenden Rechners",
            "Uhrzeit der Serveranfrage",
            "IP-Adresse",
          ]}
        />
        <Paragraph text="Eine Zusammenführung dieser Daten mit anderen Datenquellen wird nicht vorgenommen." />
        <Paragraph text="Grundlage für die Datenverarbeitung ist Art. 6 Abs. 1 lit. f DSGVO, der die Verarbeitung von Daten aufgrund berechtigten Interesses gestattet. In diesem Fall besteht ein berechtigtes Interesse an einem sicheren und störungsfreien Betrieb des Webservers." />
        <Paragraph text="Um diesen sicherzustellen, müssen über die Server-Logfiles Angriffe und Fehlfunktionen des Systems erkannt und nachvollzogen werden können. Um Angriffsmuster zu erkennen und den Angreifer bzw. dessen Provider ggf. rückverfolgen zu können, müssen Zugriffe auf den Server für eine gewisse Dauer unter Einschluss der rückverfolgbaren Quelle des Zugriffs (vollständige IP-Adresse) gespeichert werden. Die Höchstdauer der Speicherung beträgt sieben Tage." />
        <Paragraph text="Sie können dieser Verarbeitung Ihrer Daten jederzeit aus Gründen, die sich aus Ihrer besonderen Situation ergeben, widersprechen. In diesem Falle prüfen wir im Einzelfall, ob wir Ihre Daten zum störungsfreien Betrieb des Webservers für die Restdauer der Speicherung noch benötigen. Sollte dies nicht der Fall sein, löschen wir Ihre Daten." />
        <SubHeading text="Kontaktformular / Newsletter / Webinare / Veranstaltungen" />
        <Paragraph text="Wenn Sie uns über das Kontaktformular auf der Website kontaktieren, erheben wir die von Ihnen eingegebenen Daten neben dem von Ihnen selbst bestimmten Zweck der Kontaktaufnahme auch generell zu Zwecken des Direktmarketings. Die Verarbeitung zu Direktmarketing-Zwecken schließt insbesondere auch die Versendung von (anderen) Newslettern und Publikationen sowie von Webinar- oder Veranstaltungseinladungen mit ein. Für die Verarbeitung der bei Ihnen angegebenen personenbezogenen Daten (einschließlich der bei Nutzung des Kontaktformulars in Ihrer elektronischen Kommunikation enthaltenen personenbezogenen Daten) gelten die in dieser Datenschutzerklärung gegebenen Ausführungen (siehe insbesondere Ausführungen gemäß Art. 12 / 13 DSGVO unter Punkt 6)." />
        {/* <Listing
          elements={[
            "Zweck(e) der Datenverarbeitung: Direktmarketing, insbesondere generelle Kontaktaufnahme, Newsletter-Versendung, Webinar- oder Veranstaltungseinladungen, Versendung von Publikationen oder Hinweisen",
            "Rechtsgrundlage(n) der Datenverarbeitung und berechtigtes Interesse: Art. 6 Abs. 1 S. 1 lit. f DSGVO – Direktmarketing",
            "Zusätzliche Empfänger der personenbezogenen Daten (neben unserem Server-Hoster): Keine",
            "Dauer der Speicherung der personenbezogenen Daten bzw. Kriterien hierfür: Bis zu Ihrem Widerspruch",
            "Rechte des Betroffenen: Zusätzlich zu den oben angegeben Rechten besteht das Recht auf jederzeitigen Widerspruch gegen die Verarbeitung.",
            "Ist die Bereitstellung der personenbezogenen Daten gesetzlich oder vertraglich vorgeschrieben oder für einen Vertragsabschluss erforderlich oder ist die betroffene Person verpflichtet, die personenbezogenen Daten bereitzustellen? Welche Folgen hätte die Nichtbereitstellung?: Nein",
            "Besteht eine automatisierte Entscheidungsfindung einschließlich Profiling?: Nein",
          ]}
        /> */}
      </div>
      <Heading num={3} text="Soziale Medien" />
      <div className="space-y-4">
        <SubHeading text="Inhalte teilen über Plugins (Facebook, Twitter, Xing und LinkedIn)" />
        <Paragraph text="Die Inhalte auf unseren Seiten können in sozialen Netzwerken (Facebook, Twitter, Xing und LinkedIn) geteilt werden. Hierfür sind auf unserer Website Links auf das jeweilige Netzwerk angebracht. Es handelt sich hierbei nicht um sog. Plug-Ins, die bereits beim Laden der Website einen Kontakt zum Netzwerk-Betreiber herstellen, sondern um Links. Erst beim Klicken auf den Link baut Ihr Browser – wie bei jedem Link – eine Verbindung zu dem im Link angegebenen Server auf. Eine direkte Datenübermittlung zwischen unserem Server und dem Netzwerk-Betreiber findet nicht statt." />
      </div>
      <Heading num={4} text="Sonstige Plugins und Tools" />
      <div className="space-y-4">
        <SubHeading text="Google Web Fonts" />
        <Paragraph text="Diese Seite nutzt zur einheitlichen Darstellung von Schriftarten sogenannte Web Fonts, die von Google bereitgestellt werden. Beim Aufruf einer Seite lädt Ihr Browser die benötigten Web Fonts in ihren Browsercache, um Texte und Schriftarten korrekt anzuzeigen. Zu diesem Zweck muss der von Ihnen verwendete Browser Verbindung zu den Servern von Google aufnehmen. Hierdurch erlangt Google Kenntnis darüber, dass über Ihre IP-Adresse unsere Website aufgerufen wurde. Die Nutzung von Google Web Fonts erfolgt im Interesse einer einheitlichen und ansprechenden Darstellung unserer Online-Angebote. Dies stellt ein berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar. Wenn Ihr Browser Web Fonts nicht unterstützt, wird eine Standardschrift von Ihrem Computer genutzt." />
        <p>
          Weitere Informationen zu Google Web Fonts finden Sie unter{" "}
          <a
            className="text-blue-600"
            href="https://developers.google.com/fonts/faq"
            target="_blank"
          >
            https://developers.google.com/fonts/faq
          </a>{" "}
          und in der Datenschutzerklärung von Google:{" "}
          <a
            className="text-blue-600"
            href="https://www.google.com/policies/privacy/"
            target="_blank"
          >
            https://www.google.com/policies/privacy/
          </a>
          .
        </p>
      </div>
      <Heading num={5} text="E-Mails" />
      <div className="space-y-4">
        <Paragraph text="Wenn Sie uns eine E-Mail schreiben, verarbeiten wir Ihre Kontaktdaten (wie Name, Adresse, E-Mail-Adresse, Telefonnummer) zu unterschiedlichen Zwecken." />
        <Paragraph text="Wir verarbeiten Ihre Kontaktdaten (wie Name, Adresse, E-Mail-Adresse, Telefonnummer) – ggf. neben den oben bezeichneten Zwecken – zu Direktmarketing-Zwecken bis auf Ihren – jederzeit möglichen – Widerruf. Die entsprechenden Informationshinweise entnehmen Sie bitte Punkt 6." />
      </div>
      <Heading
        num={6}
        text="Informationen zur Verarbeitung personenbezogener Daten für Direktmarketing-Zwecke"
      />
      <div className="space-y-4">
        <Paragraph text="Sie können der Verwendung Ihrer personenbezogenen Daten zu Zwecken des Direktmarketings bzw. der Werbung jederzeit widersprechen, ohne dass hierfür andere als die Übermittlungskosten nach den Basistarifen entstehen. Verwenden Sie dafür einfach die unten angegebenen Kontaktdaten des Verantwortlichen." />
        <Paragraph text="Gemäß Art. 12 / 13 DSGVO ist die brantpoint GmbH als datenschutzrechtlich Verantwortliche verpflichtet, Ihnen als betroffener Person bei Erhebung Ihrer personenbezogenen Daten zum Zeitpunkt der Erhebung Folgendes mitzuteilen:" />
        <SubHeading text="Verantwortlicher" />
        <div className="flex flex-col">
          <div className="">brantpoint GmbH</div>
          <div>Schackstraße 2</div>
          <div>80539 München</div>
        </div>
        <div>
          <div className="flex space-x-4">
            <span>Tel.:</span>
            <span>+49 89 38172 0</span>
          </div>
          <div className="flex space-x-4">
            <span>E-Mail:</span>
            <span>info@brantpoint.io</span>
          </div>
          <div className="flex space-x-4">
            <span>Internet:</span>
            <span>www.brantpoint.io</span>
          </div>
        </div>
        <SubHeading text="Kategorien der Daten" />
        <Paragraph text="Name, (betriebliche) Adresse, E-Mail-Adresse, ggf. Unternehmenszugehörigkeit, ggf. (betriebliche) Telefonnummer (soweit diese von Ihnen zur Verfügung gestellt wurde)." />
        <SubHeading text="Zweck(e) der Datenverarbeitung" />
        <Paragraph text="Direktmarketing (insbesondere individuelle Ansprache, Einladung zu Veranstaltungen/Webinaren, Versendung von Informationsmaterial wie Newslettern und Broschüren, Weihnachtskarten)." />
        <SubHeading text="Rechtsgrundlage(n) der Datenverarbeitung" />
        <Paragraph text="Art. 6 Abs. 1 S. 1 lit. f DSGVO" />
        <Paragraph text="Berechtigtes Interesse: Direktmarketing" />
        <SubHeading text="Empfänger der personenbezogenen Daten" />
        <Paragraph text="Zugriff durch Auftragsverarbeiter:" />
        <Listing
          elements={[
            "Server-Hoster",
            "Softwareanbieter im Rahmen der IT-Fernwartung",
            "Marketing-Agentur",
          ]}
        />
        <SubHeading text="Dauer der Speicherung der personenbezogenen Daten bzw. Kriterien hierfür" />
        <Paragraph text="Die Daten werden bis zu einem Widerspruch gegen die weitere Verarbeitung bzw. bis zum Ende der Geschäftsbeziehung gespeichert." />
        <SubHeading text="Rechte des Betroffenen" />
        <Listing
          elements={[
            "Sie haben das Recht, jederzeit Widerspruch gegen die Verarbeitung der Sie betreffenden personenbezogenen Daten zum Zwecke des Direktmarketings einzulegen.",
            "Nach Maßgabe von Art. 15 DSGVO haben Sie das Recht, Auskunft über die zu Ihrer Person gespeicherten Daten einschließlich eventueller Empfänger und der geplanten Speicherdauer zu erhalten.",
            "Sollten unrichtige personenbezogene Daten verarbeitet werden, steht Ihnen gemäß Art. 16 DSGVO ein Recht auf Berichtigung zu.",
            "Liegen die gesetzlichen Voraussetzungen vor, so können Sie die Löschung oder Einschränkung der Verarbeitung verlangen (Art. 17 und 18 DSGVO).",
            "Wenn die in Art. 20 DSGVO geregelten Voraussetzungen vorliegen, steht Ihnen ein Recht auf Datenübertragbarkeit zu.",
            "Sie haben zudem das Recht, sich bei einer Datenschutz-Aufsichtsbehörde über die Verarbeitung Ihrer personenbezogenen Daten zu beschweren.",
          ]}
        />
        <SubHeading text="Ist die Bereitstellung der personenbezogenen Daten gesetzlich oder vertraglich vorgeschrieben oder für einen Vertragsabschluss erforderlich oder ist die betroffene Person verpflichtet, die personenbezogenen Daten bereitzustellen? Welche Folgen hätte die Nichtbereitstellung?" />
        <Paragraph text="Nein." />
        <SubHeading text="Besteht eine automatisierte Entscheidungsfindung einschließlich Profiling?" />
        <Paragraph text="Nein" />
      </div>
    </div>
  )
}

function Heading({ text, num }: { text: string; num: number }) {
  return (
    <div
      className={
        num === 1 ? "font-bold text-4xl mb-8" : "font-bold text-4xl mt-20 mb-8"
      }
    >
      {num}. {text}
    </div>
  )
}

function SubHeading({ text }: { text: string }) {
  return <div className="font-bold text-xl"> {text}</div>
}

function Paragraph({ text }: { text: string }) {
  return <p>{text}</p>
}

function Listing({ elements }: { elements: string[] }) {
  return (
    <ul className="list-disc list-inside">
      {elements.map((element, index) => {
        return <li key={index}>{element}</li>
      })}
    </ul>
  )
}
